import React from 'react';
import { Layout } from '../components/layout';
import { graphql } from 'gatsby';
import SEO from '../components/seo';
import classnames from 'classnames';

export const pageQuery = graphql`
  query ($path: String!) {
    markdownRemark(frontmatter: { path: { eq: $path } }) {
      html
      frontmatter {
        title
        shortTitle
        breadcrumbs {
          title
          path
        }
      }
      headings {
        value
        depth
      }
    }
  }
`;

interface Props {
  data: {
    markdownRemark: {
      html: string;
      frontmatter: {
        title: string;
        shortTitle?: string;
        breadcrumbs?: Array<{ title: string; path: string }>;
      };
      headings: Array<{ value: string; depth: number }>;
    };
  };
}

export default function Handbook({ data }: Props): JSX.Element {
  const { html, frontmatter, headings } = data.markdownRemark || {
    frontmatter: {},
    headings: [],
    html: '',
  };
  const breadcrumbs = frontmatter.breadcrumbs ?? [];

  return (
    <Layout>
      <SEO title={frontmatter.title} />
      <div className="handbook">
        <div className="handbook__sidebar">
          <div className="sidebar">
            {headings
              .filter((e) => e.depth < 3)
              .map((heading) => (
                <a
                  className={classnames(
                    'sidebar__link',
                    `sidebar__link--${heading.depth + 1}`
                  )}
                  href={'#' + heading.value.toLowerCase().split(' ').join('-')}
                  key={heading.value}
                >
                  {heading.value}
                </a>
              ))}
          </div>
        </div>
        <div className="container">
          <nav className="breadcrumb">
            <a href="/">Home</a>
            {breadcrumbs.map((e) => (
              <a key={e.title} href={e.path}>
                {e.title}
              </a>
            ))}
            <span>{frontmatter.shortTitle ?? frontmatter.title}</span>
          </nav>
          <h1>{frontmatter && frontmatter.title}</h1>
          <div
            id="md_content"
            className="handbook__content"
            dangerouslySetInnerHTML={{
              __html: html
                .replace(/h4/g, 'h5')
                .replace(/h3/g, 'h4')
                .replace(/h2/g, 'h3')
                .replace(/h1/g, 'h2'),
            }}
          ></div>
        </div>
      </div>
    </Layout>
  );
}
